<template>
  <div>
    <page-title :heading="$t('analytic.lang_garnishAnalytics')" :icon="icon"
                :subheading="$t('analytic.lang_garnishAnalytics')"></page-title>
    <div class="app-main__inner">
      <garnish-report-component/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import GarnishReportComponent
  from "../../../../components/analytics/warewanlytics/garnishreport/GarnishReportComponent";

export default {
  components: {
    GarnishReportComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-graph1 icon-gradient bg-tempting-azure',
  })
}
</script>