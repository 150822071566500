<template>
  <v-container fluid>
    <v-card>
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'card-header']">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ $t('analytic.lang_garnishAnalytics')}}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">
          <b-dropdown no-caret right toggle-class="btn-icon btn-icon-only" :menu-class="[this.$vuetify.theme.dark? 'dark-bg' : '']" variant="link">
            <span slot="button-content"><i class="pe-7s-menu btn-icon-wrapper"></i></span>
            <div>
              <h6 class="dropdown-header" tabindex="-1">
                {{ $t('generic.lang_Options') }}</h6>
<!--              <button @click="exportPDF" class="dropdown-item" tabindex="0" type="button"
              >
                <v-icon class="dropdown-icon" color="warning">picture_as_pdf</v-icon>
                <span>Export PDF</span>
              </button>-->

              <button :class="[this.$vuetify.theme.dark? 'white--text' : '', 'dropdown-item']" tabindex="0" type="button" @click="exportExcel">
                <font-awesome-icon class="dropdown-icon success--text"
                                   icon="file-excel"/>
                <span>Excel</span>
              </button>
            </div>
          </b-dropdown>
        </div>
      </v-card-title>
      <v-card-text class=" pt-2">
        <v-row align="center" justify="center">

          <v-col class="pb-0 mb-0" cols="4" sm="6">
            <v-select :items="this.dateOptions" class="mx-auto" dense item-text="name"
                      item-value="id" outlined
                      v-model="model"/>
          </v-col>

          <v-col class="pb-0 mb-0" cols="4" sm="6">
            <v-select :items="limits" dense :label="$t('accounting.lang_numberOfResults')" outlined v-model="limit"
                      item-text="name" item-value="limit"/>
          </v-col>

          <v-col class="pb-0 mb-0" cols="4" sm="12">
            <v-select :items="cashierIDs" :label="$t('accounting.lang_reportForCashierID')" class="mx-auto" dense
                      outlined
                      v-model="currentCashierID" multiple/>
          </v-col>

          <v-col class="mt-0 pt-0" cols="12" sm="6" v-if="this.model==9">
            <BaseDateInput dense :label="$t('generic.lang_from')" outlined type="date" v-model="start"/>
          </v-col>

          <v-col class="mt-0 pt-0" cols="12" sm="6" v-if="this.model==9">
            <BaseDateInput dense :label="$t('generic.lang_till')" outlined type="date" v-model="end"/>
          </v-col>
          <v-col class="pb-0 mb-0" cols="4" sm="6">
            <v-select :items="garnishGroups" dense :label="$t('erp.lang_garnishGroups')" outlined multiple v-model="garnishGroup"
                      item-text="name" item-value="uuid"  />
          </v-col>

          <v-col class="pb-0 mb-0" cols="4" sm="6">
            <v-select :items="wareGroups" :label="$t('generic.lang_waregroup')" class="mx-auto" dense
                      outlined item-text="name" item-value="id"
                      v-model="wareGroup" multiple/>
          </v-col>
          <v-col class="pb-0 mb-0" cols="12">
            <v-autocomplete
                :items="Items"
                multiple
                :label="$t('erp.lang_items')"
                item-value="id"
                item-text="name"
                :loading="loadingItems"
                :search-input.sync="search"
                :filter="itemsFilter"
                outlined deletable-chips chips small-chips clearable
                dense
                v-model="item"

            >
              <template v-slot:item="{ item }">
                <strong>{{ item.name }}</strong>
                <span>({{ item.ean }})</span>
              </template>

            </v-autocomplete>
          </v-col>

          <v-col class="mt-0 pt-0" cols="12">
            <v-btn :disabled="this.loading|| this.currentCashierID.length===0" :loading="this.loading"
                   @click="showChart" block
                   class="bg-primary text-light mx-auto">
              <v-icon class="ma-1">remove_red_eye</v-icon>
              {{ this.$t('generic.lang_display') }}
            </v-btn>
          </v-col>
          <v-col class="pa-0 ma-0" cols="12">
            <v-divider class="pa-0 ma-0"/>
          </v-col>
          <v-col cols="12">
            <v-card v-if="showTable">
              <v-card-text class="pa-0">
                <v-data-table
                    :items="dataToShow"
                    :headers="dataTableHeaders"
                    :sort-desc.sync="sortDesc"
                >
                  <template v-slot:item.averageSale="{item}">
                    {{item.averageSale | currency}}
                  </template>
                  <template v-slot:item.averageBuy="{item}">
                    {{item.averageBuy | currency}}
                  </template>
                  <template v-slot:item.averageSaleAcc="{item}">
                    {{item.averageSaleAcc | currency}}
                  </template>
                  <template v-slot:item.averageBuyAcc="{item}">
                    {{item.averageBuyAcc | currency}}
                  </template>
                  <template v-slot:item.margin="{item}">
                    {{item.margin | currency}}
                  </template>
                  <template v-slot:item.marginAcc="{item}">
                    {{item.marginAcc | currency}}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- pdf dialog-->
    <v-dialog persistent v-model="showPDF" width="650">
      <v-card>
        <v-card-text style="height: 600px;">
          <iframe :src="iframePDFContent" height="550" v-if="iframePDFContent.length > 0" width="600"/>
        </v-card-text>

        <v-card-actions style="background-color: white;">
          <v-spacer></v-spacer>
          <v-btn @click="showPDF = false" color="blue darken-1" dark text>{{ $t('generic.lang_close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import VueApexCharts from 'vue-apexcharts'
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFileExcel,} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {ENDPOINTS} from "@/config";
import moment from "moment";
import {mapState} from "vuex";
import {Events} from "@/plugins/events";
import FileSaver from 'file-saver';
import BaseDateInput from "@/components/common/BaseDateInput.vue";

library.add(
    faFileExcel
);

var self = null;

export default {
  name: "GarnishReportComponent",
  components: {
    'apexchart': VueApexCharts,
    DatePicker,
    'font-awesome-icon': FontAwesomeIcon,
    BaseDateInput
  },
  watch:{
    item(v){
      console.log(v)
    },
    search(value){

      if(!(value && value.length>1))
        return;
      this.loadingItems=true;
      clearTimeout(this.awaitingSearch);
      this.awaitingSearch = setTimeout(() => {
        this.Items = [];
        this.axios
            .post(ENDPOINTS.ERP.ITEM.SEARCH2, { query: value })
            .then((res) => {
              this.Items = [...res.data];
            })
            .catch((err) => {

            })
            .finally(() => {
              this.loadingItems=false;
            });
      },1000)
    }
  },
  data() {
    return {
      showPDF: false,
      iframePDFContent: "",
      isCompare: false,
      start: "",
      end: "",
      comparedStart: "",
      comparedEnd: "",
      model: 9,
      sortBy: 'price',
      sortDesc: true,
      currentCashierID: [],
      selectedCashiers: 0,
      limits: [
        {
          name: '10',
          limit: 10,
        },
        {
          name: '25',
          limit: 25,
        },
        {
          name: '50',
          limit: 50,
        },
        {
          name: '100',
          limit: 100,
        },
        {
          name: this.$t('generic.lang_all'),
          limit: 0,
        },
      ],
      comparedOptions: {
        chart: {
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
            }
          },
          background: "#f6f8f9",
          type: 'bar',
          height: 0,
          width: "100%",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
        xaxis: {
          categories: [],
        },
      },
      options: {
        plotOptions: {
          pie: {
            size: 30,
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: this.$t('generic.lang_total')
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(2) + "%(" + Math.ceil((val / 100) * self.total) + ")";
          },
        },
        chart: {
          type: 'donut',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      series: null,
      comparedSeries: [
        {
          data: [],
          name: ""
        },
        {
          data: [],
          name: ""
        },
      ],
      loading: false,
      loadingItems: false,
      limit: 10,
      total: 0,
      showTable: false,
      showComparedChart: false,
      showTopChart: false,
      garnishGroup: null,
      wareGroup: null,
      dataToShow: [],
      garnishGroups: [],
      Items: [],
      item: null,
      search: null,
      awaitingSearch: null,
      wareGroups: [],
      dataTableHeaders: [
        {
          text: this.$t('erp.lang_ware_create_id'),
          value: "ean",
          align: 'left'
        },
        {
          text:this.$t('generic.lang_name'),
          value: "name",
          align: 'left'
        },
        {
          text: this.$t('erp.lang_garnishGroup'),
          value: "garnishGroup",
          align: 'left'
        },
        {
          text: this.$t('erp.lang_QTY'),
          value: "qty",
          align: 'right'
        },
        {
          text: this.$t('analytic.lang_averageSalePriceItem'),
          value: "averageSale",
          align: 'right'
        },
        {
          text: this.$t('analytic.lang_averageBuyPriceItem'),
          value: "averageBuy",
          align: 'right'
        },
        {
          text: this.$t('analytic.lang_averageSalePriceCummulated'),
          value: "averageSaleAcc",
          align: 'right'
        },
        {
          text: this.$t('analytic.lang_averagepurchasePriceCummulated'),
          value: "averageBuyAcc",
          align: 'right'
        },
        {
          text: this.$t('accounting.lang_itemMargin'),
          value: "margin",
          align: 'right'
        },
        {
          text: this.$t('accounting.lang_marginTotal'),
          value: "marginAcc",
          align: 'right'
        },
        {
          text: this.$t('generic.lang_lastSold'),
          value: "lastSold",
          align: 'left'
        },
      ],
    }
  },
  computed: {
    dateOptions() {
      return [
        {
          id: 1,
          name: this.$t('generic.lang_today'),
        },
        {
          id: 2,
          name: this.$t('generic.lang_yesterday'),
        },
        {
          id: 3,
          name: this.$t('generic.lang_thisWeek'),
        },
        {
          id: 4,
          name: this.$t('generic.lang_last_week'),
        },
        {
          id: 5,
          name: this.$t('generic.lang_this_month'),
        },
        {
          id: 6,
          name: this.$t('generic.lang_last_month'),
        },
        {
          id: 7,
          name: this.$t('generic.lang_this_year'),
        },
        {
          id: 8,
          name: this.$t('generic.lang_last_year'),
        },
        {
          id: 9,
          name: this.$t('generic.lang_from_to'),
        },
      ]
    },
    ...mapState("cashierIDs", {"cashierIDs": state => state.availableCashierIDs}),
    ...mapState("api/auth", {"currentID": state => state.cashierID}),
  },
  methods: {
    itemsFilter(item, queryText) {
      return (
          item.name?.toLowerCase().includes(queryText?.toLowerCase()) ||
          item.ean?.toLowerCase().includes(queryText?.toLowerCase())
      );
    },
    showChart() {
      this.loading = true;
      this.showTable = false;
      this.showTopChart = false;
      this.showComparedChart = false;
      this.series = null;
      this.total = 0;
      this.options.labels = [];

      if (this.isCompare) {
        this.comparedChart();
        return;
      }

      this.axios.post(ENDPOINTS.ANALYTICS.WARE.GARNISH, this.getForm()).then((res) => {
        if (res.data.success == true) {
          if (res.data.data.length > 0) {
            this.prepareDataTable(res.data.data)
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_nothingtoshow'),
              color: "warning"
            });
          }
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    prepareDataTable(data) {
      this.showTable = false;
      let dataToRender = [], row = [];
      data.forEach((el, i) => {
        row = {};
        row['ean'] = el[0]
        row['name'] = el[1]
        row['garnishGroup'] = el[2]
        row['qty'] = el[3]
        row['averageSale'] = el[4]
        row['averageBuy'] = el[5]
        row['averageSaleAcc'] = el[6]
        row['averageBuyAcc'] = el[7]
        row['margin'] = el[8]
        row['marginAcc'] = el[9]
        row['lastSold'] = el[10]

        dataToRender.push(row);
      })
      this.showTable = true;
      this.dataToShow = dataToRender;
    },
    getForm() {
      let form = {};
      let now = Math.floor(Date.now() / 1000);
      let cashierID = this.currentCashierID;
      this.selectedCashiers = this.currentCashierID.length;

      if (this.model == 1)
        form = {
          startDate: moment.unix(now).startOf("days").unix(),
          endDate: now,
          limit: this.limit
        }
      else if (this.model == 2)
        form = {
          startDate: moment.unix(now).startOf("days").subtract(1, "days").unix(),
          endDate: moment.unix(now).startOf("days").subtract(1, "seconds").unix(),
          limit: this.limit
        }
      else if (this.model == 3)
        form = {
          startDate: this.getThisWeek(),
          endDate: now,
          limit: this.limit
        }
      else if (this.model == 4)
        form = {
          startDate: this.getLastWeek(),
          endDate: moment.unix(this.getLastWeek()).endOf("weeks").unix(),
          limit: this.limit
        }
      else if (this.model == 5)
        form = {
          startDate: this.getThisMonth(),
          endDate: now,
          limit: this.limit,
        }
      else if (this.model == 6) {
        form = {
          startDate: this.getLastMonth(),
          endDate: moment.unix(this.getLastMonth()).endOf("months").unix(),
          limit: this.limit,
        }
      } else if (this.model == 7)
        form = {
          startDate: this.getThisYear(),
          endDate: now,
          limit: this.limit
        }
      else if (this.model == 8) {
        form = {
          startDate: this.getLastYear(),
          endDate: moment.unix(this.getLastYear()).endOf("years").unix(),
          limit: this.limit
        }
      } else if (this.model == 9) {
        form = {
          startDate: this.getFrom(),
          endDate: this.getTo(),
          limit: this.limit,
        }
      }
      form.cashierID = cashierID;
      form.garnishGroupIDs = this.garnishGroup||[];
      form.itemIDs = this.item||[];
      form.itemGroupIDs = this.wareGroup||[];

      return form;
    },
    loadGarnishGroups() {
      this.loading = true;
      this.axios.post(ENDPOINTS.ERP.GARNISHGROUP.GET_ALl_WITH_ITEMS)
          .then((res) => {
            if (res.data.STATUS === "SUCCESS") {
              this.garnishGroups = res.data.groups;
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        })
      }).finally(() => {
        this.loading = false
      })
    },
    getComparedFrom() {
      if (this.isCompare) {
        let startDate = new Date(this.comparedStart);
        return moment.unix(startDate.getTime() / 1000).startOf("days").unix();
      } else
        return moment.unix(Date.now() / 1000).startOf("days").unix();
    },
    getComparedTo() {
      if (this.isCompare) {
        let endDate = new Date(this.comparedEnd);
        return moment.unix(endDate.getTime() / 1000).endOf("days").unix();
      } else
        return moment.unix(Date.now() / 1000).endOf("days").unix();
    },
    getFrom() {
      let startDate = new Date(this.start);
      return moment.unix(startDate.getTime() / 1000).startOf("days").unix();
    },
    getTo() {
      let endDate = new Date(this.end);
      return moment.unix(endDate.getTime() / 1000).endOf("days").unix();
    },
    getLastYear() {
      let current = moment.unix(Date.now() / 1000)

      return current.startOf("years").subtract(1, 'years').unix();
    },
    getThisYear() {
      let current = moment.unix(Date.now() / 1000);

      return current.startOf("years").unix();
    },
    getLastMonth() {
      let current = moment.unix(Date.now() / 1000);
      return current.startOf("months").subtract(1, "months").unix();
    },
    getThisMonth() {
      let current = moment.unix(Date.now() / 1000);
      return current.startOf("months").unix();
    },
    getLastWeek() {
      let current = moment.unix(Date.now() / 1000);

      return current.startOf("weeks").subtract(1, "weeks").unix()
    },
    getThisWeek() {
      let current = moment.unix(Date.now() / 1000);
      return current.startOf("weeks").unix();
    },
    exportExcel() {
      this.axios.post(ENDPOINTS.ANALYTICS.WARE.EXPORT.EXCEL.GARNISH, this.getForm(),
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        //DOWNLOAD pdf
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }), "Garnish report.xlsx");
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + '\n' + err.message,
          color: "error"
        });
      }).finally(() => {
      })
    },
    /*exportPDF() {
      this.axios.post(ENDPOINTS.ANALYTICS.WARE.EXPORT.PDF.TOPWARES, this.getForm(),
          {
            responseType: 'arraybuffer',
          }).then((res) => {


        //GENERATE PDF CONTENT FOR iFRAME
        const blobContent = new Blob([res.data], {type: "application/pdf"});
        this.iframePDFContent = URL.createObjectURL(blobContent);

        this.showPDF = true;

        //DOWNLOAD pdf
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), "Products Analytics.pdf");
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + '\n' + err.message,
          color: "error"
        });
      }).finally(() => {

      })
    },*/
  },
  async mounted() {
    self = this;
    this.currentCashierID.push(this.currentID);
    this.loadGarnishGroups();
    this.wareGroups = await this.$store.dispatch("itemgroups/getItemgroups");
  }
}
</script>

<style>
.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  top: 6px !important;
}
</style>
